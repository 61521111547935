const CardDataMap: any = {

  "/ClientManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/ContractManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/OSProductManagement": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/HardwareProductManagement": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/LicenseListManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/LicenseManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/EquipmentRecord": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/EquipmentManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/OTAManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/OTAUpdate": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/OrganizationManagement": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/RoleMaintenance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/UserMaintenance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/MenuManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/DictManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/LogManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/operLog": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/loginLog": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/FaultManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/ApplicationManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "/atomic-center/eventManager": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  "product/productPortal22": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "22px" },
      },
    ],
  }),
  // 用户管理
  "/AccountManagement": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/osaas/v1/api/system/manage/user/count",
          params: {
            method: "POST",
            data: {},
          },
          resDataKey: "data",
        },
      },
    ],
  }),
  // 设备管理
  "/DeviceManagement": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "License已激活",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/osaas/v1/api/statistic/get/activation/count",
          params: {
            method: "POST",
            data: {
              sourceType: 'PC',
              // deviceType: ''
            },
          },
          resDataKey: "data.licenseActivationCount",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "用户已激活",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/osaas/v1/api/statistic/get/activation/count",
          params: {
            method: "POST",
            data: {
              sourceType: 'PC',
              // deviceType: ''
            },
          },
          resDataKey: "data.activationCount",
        },
      },
    ],
  }),
};

export default CardDataMap;
